import { ref, Ref } from 'vue';

import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';

import { AccountApi } from '@/api';

interface ReportHeader {
  isLoading: Ref<boolean>;
  avatar: Ref<string>;
}

export const useReportHeader = ({
  accountId
}: {
  accountId: number;
}): ReportHeader => {
  const avatar = ref('');

  // アカウント画像の取得
  const [isLoading, fetch] = useLoading(_fetch);
  (async () => {
    avatar.value = await fetch({ accountId });
  })();

  return {
    isLoading,
    avatar
  };
};

const _fetch = async ({ accountId }): Promise<string> => {
  // アカウント画像の取得
  try {
    const { data } = await AccountApi.getAccountsAccountIdAvatarImage(
      accountId,
      {
        responseType: 'blob'
      }
    );
    return convert(data);
  } catch (e) {
    toast({
      title: 'ユーザー画像取得に失敗しました',
      variant: 'error'
    });
    return '';
  }
};

const convert = (data: Blob) => {
  return URL.createObjectURL(data);
};
