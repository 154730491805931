import { ref, Ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute, RouteLocationNormalizedLoaded } from 'vue-router';
import { roundNumber } from '@/common/formatter';
import { useAreaOptions } from '@/store/areaOptions';
import { CompanyApi } from '@/api';
import {
  AreaInfoIdEnum,
  ReachAnalysisProjectForm,
  CmSalesImpactReportsDetails,
  ProjectBasicInfo,
  ReachAnalysisProject,
  ReachAnalysisTargetSetting,
  ReachAnalysisComparisonSetting,
  ProjectBasicInfoCmBuyingKindEnum,
  ReachAnalysisTargetSettingTargetTypeEnum,
  CmSalesImpactReportsDetailsProductTypeEnum
} from '@/api/openapi';
import { Select, Area, SelectValueReachBasisFrequency } from '../types';
import useLoading from '@/composables/loading';
import { toast } from '@/components/ui/Toast';

import {
  useAdvertisingCost,
  useSelectValueReachBasisFrequency,
  AdvertisingCost
} from '@/composables/campaign/cmsi/product/reportCreate';

interface FormValue {
  selectValueProductList: Ref<Select>;
  areaValue: Ref<AreaInfoIdEnum>;
  advertisingCostValue: Ref<string>;
  selectValueReachBasisFrequency: Ref<Select>;
}

interface SelectProductList {
  selectValueProductList: Ref<Select>;
}

interface CreateReach {
  isLoading: Ref<boolean>;
  onCreate: (FormValue) => Promise<ReachAnalysisProject>;
}

interface ReachProjectCreate
  extends Area,
    SelectProductList,
    AdvertisingCost,
    CreateReach,
    SelectValueReachBasisFrequency {
  isDisabled: Ref<boolean>;
  form: FormValue;
}

export const useReachProjectCreate = (
  report: CmSalesImpactReportsDetails
): ReachProjectCreate => {
  const route = useRoute();
  const isDisabled = ref(false);

  // 商品/ブランド
  const { selectValueProductList } = useSelectProductList();

  // エリア
  const { areaList, areaValue, isLoadingArea } = useArea(route, report);

  // 出稿金額
  const { advertisingCostValue, advertisingCostValid } = useAdvertisingCost(
    roundNumber(report.advertisingCost)
  );
  //
  const {
    selectValueReachBasisFrequency,
    optionsReachBasisFrequency
  } = useSelectValueReachBasisFrequency({ init: report.reachFrequency });

  // バリデーション
  const valids = ref({});
  watch(
    valids,
    v => {
      isDisabled.value = Object.values(v).includes(true);
    },
    { deep: true }
  ); //バリデーションまとめ

  const form = {
    selectValueProductList,
    areaValue,
    selectValueReachBasisFrequency,
    advertisingCostValue
  };
  //
  const { isLoading, onCreate } = useCreateReach();

  return {
    selectValueProductList,
    advertisingCostValue,
    advertisingCostValid,
    selectValueReachBasisFrequency,
    optionsReachBasisFrequency,
    isLoadingArea,
    areaList,
    areaValue,
    isLoading,
    onCreate,
    isDisabled,
    form
  };
};

// 商品/ブランド
const useSelectProductList = () => {
  const selectValueProductList = ref({} as Select);
  return { selectValueProductList };
};

// エリア
export const useArea = (
  route: RouteLocationNormalizedLoaded,
  report: CmSalesImpactReportsDetails
): Area => {
  const areaValue = ref(
    report.areaCode !== 'CMSI_ALL'
      ? report.areaCode
      : ('SAAS_KANTO' as AreaInfoIdEnum | undefined)
  );
  const areaOptionStore = useAreaOptions();
  const { selectOptions: areaList, isLoading: isLoadingArea } = storeToRefs(
    areaOptionStore
  );

  return {
    areaList,
    areaValue,
    isLoadingArea
  };
};

// 新規作成
export const useCreateReach = (): CreateReach => {
  const [isLoading, post] = useLoading(_postCreate);

  return {
    isLoading,
    onCreate: post
  };
};
const _postCreate = async ({ companyId, form }) => {
  try {
    const {
      data
    } = await CompanyApi.postCompaniesCompanyIdReachAnalysisProjects(
      companyId,
      form
    );
    await toast({
      title: '成功',
      message: '作成に成功しました',
      variant: 'success'
    });
    return data;
    // injectのため遷移はvue側で実施
  } catch (e) {
    toast({
      title: 'レポート作成失敗',
      message: e.message,
      variant: 'error'
    });
    return {} as ReachAnalysisProject;
  }
};
// APIに合わせるformat
export const formatForm = ({
  form,
  report
}: {
  form: FormValue;
  report: CmSalesImpactReportsDetails;
}): ReachAnalysisProjectForm => {
  const basicInfo: ProjectBasicInfo = {
    productId: form.selectValueProductList.value.value,
    projectName:
      report.productType ===
      CmSalesImpactReportsDetailsProductTypeEnum.CompetitiveCompany
        ? form.selectValueProductList.value.label
        : `${report.title}(${report.companyName})`,
    productName: form.selectValueProductList.value.label,
    campaignStartDate: report.startDate,
    campaignEndDate: report.endDate,
    cmBuyingKind: ProjectBasicInfoCmBuyingKindEnum.All,
    targetGrp: 0,
    cmCreativeIds: [],
    isCmProduct: form.selectValueProductList.value.isCmProduct
  };
  const targetSettings: ReachAnalysisTargetSetting = {
    numOfEffectiveContacts: form.selectValueReachBasisFrequency.value.value,
    area: form.areaValue.value,
    totalPlacementCost: Number(
      form.advertisingCostValue.value.replace(/,/g, '')
    ),
    targets: [],
    targetType: ReachAnalysisTargetSettingTargetTypeEnum.Individual,
    targetEffectiveReachRate: 0
  };
  const comparisonSettings: ReachAnalysisComparisonSetting = {
    inhouseComparisonProducts: [],
    pastComparisons: [],
    productComparisonProducts:
      report.productType ===
      CmSalesImpactReportsDetailsProductTypeEnum.CompetitiveCompany
        ? [{ productId: report.productId }]
        : []
  };

  return {
    basicInfo,
    targetSettings,
    comparisonSettings
  };
};
