import { CHART_OPTIONS_BASE } from '@/composables/campaign/cmsi';

export const CHART_OPTIONS = {
  ...CHART_OPTIONS_BASE,
  chart: {
    ...CHART_OPTIONS_BASE.chart,
    height: 290,
    type: 'column'
  },
  colors: ['#B5C7EF']
};
